<template>
  <div>
    <gl-title title="修改密码" backIcon></gl-title>
    <gl-card>
      <div style="width: 360px;">
        <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
          <el-form-item prop="phone" label="手机号" verify>
            <el-input v-model.trim="formData.phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item prop="passwordCode" label="验证码" verify>
            <el-input v-model.trim="formData.passwordCode" maxlength="4">
              <template #append>
                <div @click="fetchCode" style="cursor:pointer;width:60px;text-align:center;">
                  <span v-if="countdown == 60">获取验证码</span>
                  <span v-else style="color:#67c23a;"> {{countdown}} S </span>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="loginPassword" label="登陆密码" verify>
            <el-input v-model.trim="formData.loginPassword"></el-input>
          </el-form-item>
        </el-form> 

        <gl-flex>
          <el-button type="primary" @click="onSubmit" >确认</el-button>
          <el-button @click="$router.back()">关闭</el-button>
        </gl-flex>
      </div>
    </gl-card>
  </div>
</template>

<script>
import {getCode, updatePwd} from '@/api/user'
import base64 from '@/utils/base64'
export default {
  data(){
    return {
      formData: {
        phone: '',   // 手机号
        passwordCode: '',  // 验证码
        loginPassword: '', //登陆密码
      },
      loading: false,
      codeParams: {
        area: '86',
        phone: '',
        type: '6'
      },
      timer: null,
      countdown: 60, // 倒计时
      loadLock: false,
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        const params = {
          phone: this.formData.phone,
          passwordCode: this.formData.passwordCode,
          loginPassword: base64.base64Encode(this.formData.loginPassword)
        }
        const res = await updatePwd(params)
        this.$message.success('操作成功')
        this.$router.back()
      })
    },
    async fetchCode(){
      if(!this.formData.phone){
        return this.$message.warning('请输入手机号')
      }
      if(this.countdown != 60){
        return this.$message.warning('验证码已发送')
      }
      if(this.loadLock) return
      this.loadLock = true
      try{
        const params = {
          ...this.codeParams,
          phone: this.formData.phone,
        }
        const res = await getCode(params)
        this.loadLock = false
        this.$message.success('发送成功')
        this.handleTimer()
      }catch(e){
        this.loadLock = false
        this.$message.error('验证码获取失败')
      }
    },
    handleTimer(){
      this.timer = setInterval(() => {
        this.countdown --
        if(this.countdown <= 0){
          this.timer && clearInterval(this.timer)
          this.countdown = 60
        }
      }, 1000)
    },
  },
}
</script>

<style>

</style>